<template>
	<div class="ckeditor">
		<ckeditor
			:editor="editor"
			:value="content"
			@input="$emit('input', $event)"
			:config="editorConfig"
		></ckeditor>
	</div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';

import '@ckeditor/ckeditor5-build-classic/build/translations/de.js';

import { debounce } from 'lodash';
import { mapActions } from 'vuex';

export default {
	name: 'LiveEditor',
	props: {
		value: String,
		project: Object,
		survey: Object,
		page: Object
	},
	data: function () {
		return {
			editor: ClassicEditor,
			content: this.value,
			editorConfig: {
				plugins: [
					EssentialsPlugin,
					BoldPlugin,
					ItalicPlugin,
					LinkPlugin,
					ParagraphPlugin,
					Alignment,
					Heading,
					Underline,
					Strikethrough,
					Code
				],
				toolbar: {
					items: [
						'heading',
						'|',
						'alignment',
						'bold',
						'italic',
						'underline',
						'strikethrough',
						'code',
						'link',
						'undo',
						'redo'
					]
				},
				language: 'de'
			}
		};
	},
	watch: {
		value: function (val) {
			this.content = val;
			this.modifyPageData();
		}
	},
	methods: {
		...mapActions(['changePageData']),
		modifyPageData: debounce(function () {
			this.changePageData({ pageID: this.page.id, data: this.page.data });
		}, 400)
	}
};
</script>

<style lang="scss">
@import '../../scss/ckeditor_dark.scss';
</style>
